import { Injectable } from '@angular/core';
import { loginApi } from '../../api/kalenteri-api';
import moment from 'moment';
import { ApiResponse, Status } from '../../../../shared/interface';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  async login(email: string, password: string) {
    const response = await loginApi.login(email, password);
    if (response.status === Status.OK && response.data.idToken) {
      const expiresAt = moment().add(response.data.expiresIn, 'second');
      localStorage.setItem('id_token', response.data.idToken);
      localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
      this.updateAuthStatus(true);
    }
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    this.updateAuthStatus(false);
  }

  public isLoggedIn() {
    const expiration = this.getExpiration();
    if (!expiration) {
      return false;
    }
    if (moment().isAfter(expiration)) {
      this.logout();
      return false;
    }
    return true;
  }

  public isUserInRole(role: 'ADMIN' | 'USER') {
    const token = localStorage.getItem('id_token');
    if (!token) {
      return false;
    }
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.role === role;
  }

  public getUsername() {
    const token = localStorage.getItem('id_token');
    if (!token) {
      return '';
    }
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.username;
  }

  public getTargets() {
    const token = localStorage.getItem('id_token');
    if (!token) {
      return [];
    }
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.targets;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    if (expiration) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
    return undefined;
  }

  // Method that can be subscribed to when login status changes
  // e.g. in the app.component.ts
  // this.authService.authStatus.subscribe(status => {
  //   this.isLoggedIn = status;
  // });

  private authStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isLoggedIn());
  authStatus$: Observable<boolean> = this.authStatus.asObservable();

  updateAuthStatus(status: boolean) {
    this.authStatus.next(status);
  }

  public async updatePassword(username: string, password: string) {
    const response = await loginApi.updatePassword(username, password);
    return response;
  }

  public async updateUserdata(oldUsername: string, newUserName: string, password: string) {
    const usernameResponse = await loginApi.updateUsername(oldUsername, newUserName);
    const passwordResponse = await loginApi.updatePassword(newUserName, password);
    if (usernameResponse.status === Status.OK && passwordResponse.status === Status.OK) {
      this.logout();
      this.login(newUserName, password);
      return { status: Status.OK, message: 'User updated' } as ApiResponse;
    } else {
      return { status: Status.ERROR, message: 'User update failed' } as ApiResponse;
    }
  }

  public async fetchUsers(username: string) {
    const response = await loginApi.fetchUsers(username);
    return response;
  }
}
