<mat-card>
    <mat-card-title class="center"><strong>Varausehdot</strong></mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
        <p>Noudatamme majoituspalvelujen varaamisessa ja tehtyjen palveluvarausten peruuttamisessa seuraavia ehtoja. Nämä ehdot tulevat kumpaakin osapuolta sitoviksi, heti kun asiakas on tehnyt majoitusvarauksen.</p>

        <p><strong>1. VARAAMINEN JA MAKSU</strong></p>
        <p>Karhu Properties Oy ei tee alustavia varauksia. Varaus on sitova varaushetkestä alkaen, riippumatta siitä tehtiinkö varaus puhelimitse vai kirjallisena. Varaajan pitää olla täysi-ikäinen ja pyydettäessä todistaa henkilöllisyytensä.</p>
        <p>50 % majoitusvarauksesta veloitetaan 14 vrk varauksen teon jälkeen ja loput 14 vrk ennen varauksen alkamista. Mikäli varaus tehdään niin, että varauksen alkuun on alle 30 vrk, erääntyy lasku 14 vrk ennen majoituksen alkamista. Jos varauksen alkuun on vähemmän kuin 14 vrk, erääntyy koko lasku maksettavaksi heti.</p>
        <p>Poikkeuksen maksuehtoihin tekee online-varaukset, jolloin majoituksen ajankohdasta riippuen lasku maksetaan joko kokonaisuudessaan heti tai kahdessa osassa, jolloin ennakkomaksu maksetaan varauksen teon yhteydessä ja loppuosa 14 vrk ennen varauksen alkua.</p>
        <p>Toisen poikkeuksen maksuehtoihin tekee tietyt tapahtuma-ajat. Tällöin majoitusvarauksesta 50 % veloitetaan 14 vrk varauksen teon jälkeen ja loput 60 vrk ennen tapahtumaa. Festivaalivarauksille lisättävät mahdolliset festivaaliliput laskutetaan loppulaskun yhteydessä. Mikäli varaus tehdään niin, että tapahtumaan on alle 60 vrk, laskutetaan koko varaus erääntyväksi 7 vrk varauspäivästä. Jos tapahtumaan on alle 30 vrk, laskutetaan koko varaus erääntyväksi heti.</p>

        <p><strong>Normaalia tiukemmat maksu- ja peruutusehdot koskevat seuraavia ajankohtia.</strong></p>
        <p>● Uusivuosi 29.12.2023-1.1.2023</p>
        <p>● Himos Juhannus 20.-23.6.2024</p>
        <p>● Iskelmäfestivaali 27.-30.6.2024</p>
        <p>● Jysäri 4.-7.7.2024</p>
        <p>● Rally Finland 29.7.-4.8.2024</p>
        <p>● FinnMetko 26.8.-1.9.2024</p>

        <p><strong>Varaukselle lisättävät maksut</strong></p>
        <p>Varauksiin lisätään toimitusmaksu 22 €. Tiettyinä aikoina kaikkiin majoitusvarauksiin lisätään lähtösiivous, jonka järjestelmä automaattisesti lisää varaukselle majoitushinnan lisäksi. Porealtaallisissa mökeissä porealtaan käyttömaksu lisätään tiettyinä aikoina varaukselle majoitushinnan lisäksi. Mökin hinta ei sisällä näitä lisäpalveluita ja ne näkyvät varauksella eriteltyinä tuoteriveinä. Kyseiset ajankohdat voi tarkistaa osoitteesta www.himoslomat.fi/majoitus/varausehdot/tapahtuma-ajat/.</p>

        <p>Toimitamme varausten laskuhuomautukset sähköisesti. Perintäpalvelumme hoitaa Intrum Oy.</p>

        <p><strong>2. AVAIMEN LUOVUTUS JA SIIVOUS</strong></p>
        <p>Majoituskohde on käytettävissä tulopäivänä klo 16 jälkeen ja se on luovutettava siivottuna lähtöpäivänä klo 12 mennessä. Viikoilla 52-1 ja 8-9 majoituskohde on käytettävissä tulopäivänä klo 18 jälkeen ja se on luovutettava siivottuna lähtöpäivänä klo 12 mennessä.</p>
        <p>Avaimet noudetaan HimosLomien Himos Center vastaanotosta, Länsi-Himoksentie 4, 42100 Jämsä. Puh. 020 711 9210</p>

        <p>Majoituskohteen avain luovutetaan vain varaajalle tai hänen etukäteen ilmoittamalleen täysi-ikäiselle henkilölle vastaanoton aukioloaikoina. Varausnumero ja maksukuitit, sekä henkilöllisyystodistus on esitettävä Himos Center vastaanotossa avainta noudettaessa.</p>
        <p>Mikäli majoittuminen tapahtuu aukioloaikojen ulkopuolella, on siitä ilmoitettava osoitteeseen asiakaspalvelu&#64;karhuproperties.fi ja sovittava luovutus erikseen (Aukioloajat: https://www.himoslomat.fi/tuloinfo/ )</p>
        <p>Jos avain katoaa, asiakkaalta peritään lukon vaihtamisesta aiheutuneet kustannukset. Ovenavausmaksu 50 € arkisin klo 8 – 18, muina aikoina 80 €.</p>
        <p>Mikäli kohteeseen ei ole tilattu lähtösiivousta, asiakkaan tulee huolehtia lähtösiivouksesta. Edellytämme, että siivouksessa huolehditaan mm. seuraavista asioista: roskien vienti, lattioiden imurointi ja pesu, astioiden pesu (ja tiskikoneen tyhjennys), pesuhuoneen ja wc-tilojen pesu, tahrojen ja likajälkien poisto, vuoteiden petaus ja huonekalujen sekä puutarhakalusteiden ja muiden tavaroiden vieminen paikoilleen. Myös piha-alue tulee siivota. Tarkemmat siivousohjeet löytyvät mökistä/huvilasta.</p>
        <p>Vaikka lähtösiivous olisikin tilattu, edellytämme kohteen olevan normaalin asumisen jäljiltä, eli huonekalut ja muut varusteet paikoillaan ja ehjinä, astiat astianpesukoneessa tai tiskipöydällä, roskat roskiksessa sekä isoimmat tahrat ja likajäljet poistettuna. Vuokraliinavaatteet ja -pyyhkeet tulee kerätä eteisen lattialle. Myös lomakohteen piha-alue tulee olla siisti.</p>
        <p>Mikäli majoituskohde on jätetty kokonaan siivoamatta tai jos kohde vaatii siivousta asiakkaan siivouksen jälkeen, Himos Mökkipalvelut tai muu käytetty siivouspalvelu laskuttaa aiheutuneet kustannukset asiakkaalta toteutuneiden kulujen mukaisesti.</p>
        <p>Mökeissä/huviloissa on peitot ja tyynyt, mutta ei liinavaatteita (lakanoita ja pyyhkeitä). Jokaista varausta kohden on mökille/huvilalle toimitettu aloituspakkaus wc- ja talouspaperia sekä astian- ja yleispesuaineita, jotta pääsette sujuvasti loman alkuun. Mikäli tarvitsette loman aikana näitä lisää, tulee tarvikkeet hankkia itse. Lomakohteen tarkempi varustetaso ilmenee mökkikuvauksesta.</p>
        <p>Mikäli mökki-/huvilavarustukseen kuuluu ulkoporeamme/palju, sen käytöstä veloitetaan erikseen hinnaston mukainen käyttömaksu. Altaan käyttöohjeet annetaan HimosCenter vastaanotosta majoittumisen yhteydessä. HimosLomien yhteistyökumppaneilla on oikeus periä ylimääräinen huolto- tai siivousmaksu (alk. 150 €) mikäli käyttöohjeita ei ole noudatettu. Talviaikaan alueelle ei saa tuoda siirrettäviä paljuja/uima-altaita tms. Touko-lokakuussa niiden tuonti on neuvoteltavissa, tuonnista on ilmoitettava etukäteen myyntipalveluun p. 020 7119 200.</p>

        <p><strong>3. HENKILÖMÄÄRÄ</strong></p>
        <p>Majoituskohdetta ei saa käyttää useampi henkilö, kuin mitä kohdetiedoissa on mainittu, ellei siitä ole erikseen Karhu Properties Oy:n kanssa sovittu. Lisähenkilöistä veloitetaan erillinen lisähenkilömaksu. Teltalla tai asuntovaunulla / -autolla leiriytyminen loma-asunnon pihaan on kielletty.</p>

        <p><strong>4. SÄHKÖAUTOJEN LATAAMINEN</strong></p>
        <p>Sähköauton lataaminen Karhu Properties Oy:n omistamissa huviloissa on kielletty. Sähköautoja saa ladata vain niille tarkoitetuista/symbolilla merkityistä latauspisteistä. Lataaminen muista pistokkeista voi aiheuttaa paloturvallisuusriskin. Vuokraaja on velvollinen korvaamaan mahdolliset latauksesta aiheutuneet haitat.</p>

        <p><strong>5. VAHINKOJEN KORVAUS JA LÖYTÖTAVARAT</strong></p>
        <p>Varaaja on vastuussa majoituskohteesta ja sen irtaimistosta vuokrausaikana. Varaaja vastaa niistä vahingoista, jotka hän tai hänen vieraansa tahallaan tai huolimattomuudellaan, on mökin irtaimistolle, rakenteille tai vuokranantajalle kuuluvalle omaisuudelle aiheuttanut. Asiakas on velvollinen välittömästi ilmoittamaan kohteelle tai sen irtaimistolle aiheuttamistaan vahingoista ja korvaamaan ne Karhu Properties Oy:lle. Karhu Properties Oy ei vastaa asiakkaan tavaroista, joita säilytetään majoitus- tai muissa HimosLomien tiloissa, tai jotka unohtuvat niihin. Löytötavarat toimitetaan Suomen Löytötavarapalvelun toimipisteeseen Turkuun, josta niitä voi tiedustella puh. 0600 03391.</p>

        <p><strong>7. REKLAMAATIOT</strong></p>
        <p>Kaikki lomakohteen varaukseen ja kuntoon liittyvät reklamaatiot tulee osoittaa välittömästi niiden ilmaannuttua Karhu Properties Oy:lle, sähköpostilla asiakaspalvelu&#64;karhuproperties.fi Kiireelliset huoltopyynnöt tulee tehdä välittömästi, alueella on 24 h päivystys ja kiireelliset huoltotyöt tehdään viipymättä. Päivystys puh. 020 7119 213</p>

        <p><strong>8. TUPAKOINTI JA LEMMIKIT</strong></p>
        <p>Tupakointi on kielletty majoituskohteen sisätiloissa. Tupakoinnista veloitetaan 250 € tuuletusmaksu. Lemmikin tuonnista on aina ilmoitettava varauksen yhteydessä. Luvatta tuoduista lemmikeistä aiheutuneet kustannukset peritään varaajalta. Emme vastaa luvattoman tupakoinnin tai eläinpölyn asiakkaalle aiheuttamista allergioista tms. ongelmista. Karhu Properties Oy:lla ei ole välityksessä allergia- / invamökkejä tai –huviloita.</p>

        <p><strong>9. PERUUTUS</strong></p>
        <p>Peruutuksesta on aina ilmoitettava Karhu Properties Oy:lle kirjallisesti. Laskun maksamatta jättäminen ei ole peruutus! Mikäli asiakas vaihtaa lomakohdetta tai loman ajankohtaa, sitä pidetään aikaisemman varauksen peruutuksena ja uutena varauksena. Suoritettua maksua ei voida siirtää mökistä/huvilasta toiseen. Yllättävän sairauden tai tapaturman varalle suosittelemme ottamaan vakuutuksen. Muutokset säätilassa, rinne- tai reittimäärissä tms. syyt eivät muuta peruutusehtoja. Karhu Properties Oy ei ole velvollinen korvaamaan asiakkaalle mahdollisesti aiheutuvaa haittaa naapuritontin rakennustöiden aiheuttamista häiriöistä eikä omistajan majoituskohteeseen tekemistä varustelu- tai muista muutoksista, joita ei ole ilmoitettu.</p>

        <p><strong>Peruutus tapahtuma-aikojen ulkopuolella</strong></p>
        <p>Sesonkiaikojen ulkopuolella peruutuskulut ovat 95 € / huvila, jos peruutus tehdään viimeistään 14 vrk ennen varauksen alkua. Myöhemmin kuin 14 vrk ennen varauksen alkua tehdystä peruutuksesta perimme koko varauksen summan.</p>

        <p><strong>Peruutus tapahtuma-aikoina</strong></p>
        <p>Karhu Properties Oy perii peruutuskuluja 20 % majoituksen hinnasta, kuitenkin aina vähintään 95 €. Peruutuskulut ovat 50 % majoituksen hinnasta, jos peruutus tehdään 59-30 vrk ennen varauksen alkamista. Alle 30 vrk ennen varauksen alkua tehdystä peruutuksesta perimme koko varauksen summan. Peruutusmaksusta 95 € / huvila on Karhu Properties Oy:n toimistokulua.</p>

        <p><strong>10. KARHU PROPERTIES OY:N OIKEUS PERUUTTAA TAI SIIRTÄÄ VARAUS</strong></p>
        <p>Ylivoimaisen esteen sattuessa (force majeure) tai mikäli asiakas ei ole noudattanut varausehtoja, on Karhu Properties Oy:llä oikeus peruuttaa varaus.</p>

        <p>Tervetuloa viihtymään meille!</p>
    </mat-card-content>
</mat-card>
