<div class="container-center-column">
    <mat-card>
        <mat-card-title class="center">Ota yhteyttä</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="center">
            <p>Karhu Properties Oy</p>
            <p>Y-tunnus: 3435997-8</p>
            <p>Osoite: Irjalankatu 62, 33560 Tampere</p>
            <p>Puhelin:</p>
            <p>
                <a href="tel:+358407643955">+358407643955</a> Patrik
            </p>
            <p>
                <a href="tel:+358443831997">+358443831997</a> Teemu
            </p>
            <p>
                <a href="tel:+358405259468">+358405259468</a> Aleksi
            </p>
            <p>Sähköposti: <a href="mailto:asiakaspalvelu@karhuproperties.fi">asiakaspalvelu&#64;karhuproperties.fi</a></p>
        </mat-card-content>
    </mat-card>
</div>