import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { Gallery, GalleryItem, GalleryModule, ImageItem } from 'ng-gallery';
import { Lightbox, LightboxModule } from 'ng-gallery/lightbox';

interface Target {
  name: string;
  images: GalleryItem[];
}

@Component({
  selector: 'app-kuvat',
  standalone: true,
  imports: [MatGridListModule, CommonModule, MatCardModule, MatDividerModule, GalleryModule, LightboxModule, AsyncPipe],
  templateUrl: './kuvat.component.html',
  styleUrl: './kuvat.component.scss'
})
export class KuvatComponent implements OnInit {
    targets: Target[] = [];
    constructor(public gallery: Gallery, public lightbox: Lightbox) {
      lightbox.setConfig({
        keyboardShortcuts: true,
        panelClass: 'fullscreen',
        backdropClass: 'dark-backdrop',
      });
     }
  
    ngOnInit(): void {
      let imageItems = []
      for (let i = 1; i < 20; i++) {
        imageItems.push(new ImageItem({src: `/assets/images/mokki_1/mokki_1_${i}_converted.png`, thumb: `/assets/images/mokki_1/mokki_1_${i}_thumbnail.png`, alt: `Mökki 1`}));
      }
      this.targets = [
        {
          name: 'Siesta',
          images: imageItems
        },
      ];

      this.targets.forEach(target => {
        this.gallery.ref(target.name).load(target.images);
      });
    }
}
