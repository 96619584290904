<mat-card>
    <mat-card-title class="center"><strong>Rekisteriseloste</strong></mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>
        <p>Tämä on Karhu Properties Oy:n EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 29.5.2024. Viimeisin muutos 29.5.2024.</p>
        <p><strong>1. Rekisterinpitäjä</strong></p>
        <p>Karhu Properties Oy, Irjalankatu 62, 33560 Tampere</p>
        <p><strong>2. Rekisteristä vastaava yhteyshenkilö</strong></p>
        <p>Patrik Uusitalo, patrik.uusitalo&#64;gmail.com, 0407643955</p>
        <p><strong>3. Rekisterin nimi</strong></p>
        <p>Varaustietorekisteri</p>
        <p><strong>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</strong></p>
        <p>EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on</p>
        <p>- tietoinen suostumus</p>
        <p>Henkilötietojen käsittelyn tarkoitus on yhteydenpito käyttäjiin ja varauksien käsittely ja arkistointi.</p>
        <p>Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin.</p>
        <p><strong>5. Rekisterin tietosisältö</strong></p>
        <p>Rekisteriin tallennettavia tietoja ovat: henkilön nimi, yhteystiedot (puhelinnumero, sähköpostiosoite)</p>
        <p>Tietoja säilytetään asiakassuhteen ajan. Asiakassuhteen päätyttyä tiedot poistetaan rekisteristä. Asiakas voi pyytää tietojensa poistamista ottamalla yhteyttä rekisterinpitäjään.</p>
        <p><strong>6. Säännönmukaiset tietolähteet</strong></p>
        <p>Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan.</p>
        <p><strong>7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</strong></p>
        <p>Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.</p>
        <p><strong>8. Rekisterin suojauksen periaatteet</strong></p>
        <p>Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.</p>
        <p><strong>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</strong></p>
        <p>Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
        <p><strong>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</strong></p>
        <p>Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi"). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).</p>
    </mat-card-content>
</mat-card>
