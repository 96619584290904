<!-- app-footer.component.html -->
<footer class="app-footer mat-elevation-z4">
  <div class="footer-content">
    <div class="footer-left">© 2024 Karhu Properties Oy</div>
    <div class="footer-center">
      <button mat-button routerLink="/rekisteriseloste">Rekisteriseloste</button>
      <button mat-button routerLink="/varausehdot">Varausehdot</button>
      <button mat-button routerLink="/otayhteytta">Ota yhteyttä</button>
    </div>
    <div class="footer-right">
      <a href="mailto:email@example.com">asiakaspalvelu&#64;karhuproperties.fi</a>
    </div>
  </div>
</footer>
