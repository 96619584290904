import { ApiResponse, Reservation } from '../../../shared/interface';
import { environment } from '../environments/environment';

const baseUrl = environment.production ? '/api' : 'http://localhost:3000/api';
const reservationResourceUrl = baseUrl + '/reservations';

const reservationApi = {
  async fetchVaraukset(minDate: string, target: string): Promise<ApiResponse> {
    const response = await fetch(
      reservationResourceUrl + '?minDate=' + minDate + '&target=' + target
    );
    return response.json() as Promise<ApiResponse>;
  },

  async createVaraus(varaus: Reservation) {
    const response = await fetch(reservationResourceUrl + '/reserve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(varaus),
    });
    return response.json() as Promise<ApiResponse>;
  },

  async deleteVaraus(id: string) {
    const response = await fetch(reservationResourceUrl + '/delete/' + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 204) {
      return { status: 'OK', message: 'Reservation deleted' } as ApiResponse;
    } else {
      return response.json() as Promise<ApiResponse>;
    }
  },
};

const loginApi = {
  async login(username: string, password: string) {
    const response = await fetch(baseUrl + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
    return response.json() as Promise<ApiResponse>;
  },

  async updatePassword(username: string, password: string) {
    const response = await fetch(baseUrl + '/login/update/password/' + username, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });
    return response.json() as Promise<ApiResponse>;
  },

  async fetchUsers(username: string) {
    const response = await fetch(baseUrl + '/login/users/' + username);
    return response.json() as Promise<ApiResponse>;
  },

  async updateUsername(oldUsername: string, newUsername: string) {
    const response = await fetch(baseUrl + '/login/update/username', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ oldUsername, newUsername }),
    });
    return response.json() as Promise<ApiResponse>;
  }
};

export { reservationApi, loginApi };
