import { Component, EventEmitter, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-elamykset-modal',
  standalone: true,
  imports: [MatCardModule, MatDividerModule, MatButtonModule],
  templateUrl: './elamykset-modal.component.html',
  styleUrl: './elamykset-modal.component.scss'
})
export class ElamyksetModalComponent {

  constructor(private dialogRef: MatDialogRef<ElamyksetModalComponent>) {}

  close(): void {
    this.dialogRef.close();
  }

}
