<form [formGroup]="form">
  <fieldset class="container-center-column center w-1/2">
    <legend>Kirjaudu sisään</legend>
    <div class="form-field" class="w-5/6 sm:w-2/5">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Käyttäjätunnus:</mat-label>
        <input matInput name="userName" formControlName="userName" />
        <mat-error *ngIf="userNameControl.invalid"> {{ userNameErrorMessage }} </mat-error>
      </mat-form-field>
    </div>
    <div class="form-field" class="w-5/6 sm:w-2/5">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>Salasana:</mat-label>
        <input
          matInput
          name="password"
          formControlName="password"
          type="password"
        />
        <mat-error *ngIf="passwordControl.invalid"> {{ passwordErrorMessage }} </mat-error>
      </mat-form-field>
    </div>
    <div class="form-buttons">
      <button mat-raised-button color="primary" (click)="login()">
        Kirjaudu
      </button>
    </div>
  </fieldset>
</form>
