<mat-drawer-container class="drawer-container mat-typography" autosize>
  <mat-toolbar style="height: 140px; border-bottom: 1px solid gray">
    <button
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="mobileQuery.matches"
      [class.text-white]="navButtonsWhite"
    >
      <mat-icon fontIcon="menu"></mat-icon>
    </button>
    <a [routerLink]="'/'">
      <img
        class="rounded-md"
        src="assets/images/logot/karhuproperties-logo.png"
        alt="Karhu Properties Oy"
        style="height: 100px"
      />
    </a>
    <div class="button-container" [class.button-container--text-white]="navButtonsWhite" *ngIf="!mobileQuery.matches">
      <div class="center-buttons mat-typography mat-body-2">
        <button mat-button routerLink="/tietoa">Tietoa</button>
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          (click)="menuOpen = !menuOpen"
        >
          Palvelut<mat-icon iconPositionEnd>{{
            menuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
          }}</mat-icon>
        </button>
        <button mat-button routerLink="/kuvat">Kuvat</button>
        <button mat-button routerLink="/otayhteytta">Ota yhteyttä</button>
        <button mat-button routerLink="/varauskalenteri">
          Varauskalenteri
        </button>
      </div>
      <button *ngIf="isAdmin" class="flex" style="line-height: normal;" mat-icon-button (click)="openSettings()" >
        <mat-icon [inline]="true">settings</mat-icon>
      </button>
      <button *ngIf="isLoggedIn" class="flex" style="line-height: normal;" mat-icon-button (click)="logout()">
        <mat-icon [inline]="true">logout</mat-icon>
      </button>
      <button *ngIf="!isLoggedIn" class="flex" style="line-height: normal;" mat-icon-button (click)="login()">
        <mat-icon [inline]="true">login</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" (closed)="menuOpen = !menuOpen">
      <a mat-menu-item href="https://himosgolf.fi/" target="_blank"
        >Himos Golf<mat-icon iconPositionEnd>launch</mat-icon></a
      >
      <a mat-menu-item href="https://himos.fi/" target="_blank"
        >Himos Ski<mat-icon iconPositionEnd>launch</mat-icon></a
      >
      <a mat-menu-item href="https://hirvikartano.fi/" target="_blank"
        >Ravintola Hirvikartano<mat-icon iconPositionEnd>launch</mat-icon></a
      >
      <a
        mat-menu-item
        href="https://himosravintolat.fi/willilansi/"
        target="_blank"
        >Willi Länsi<mat-icon iconPositionEnd>launch</mat-icon></a
      >
      <a mat-menu-item href="https://himoslomat.fi/tekemista/" target="_blank"
        >Aktiviteetit<mat-icon iconPositionEnd>launch</mat-icon></a
      >
    </mat-menu>
  </mat-toolbar>
  <mat-drawer
    #drawer
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    style="background-color: lightgray; margin-top: 140px"
  >
    <mat-nav-list>
      <a mat-list-item (click)="drawer.toggle()" routerLink="/tietoa">Tietoa</a>
      <a mat-list-item [matMenuTriggerFor]="mobileMenu"
        >Palvelut</a
      >
      <mat-menu #mobileMenu="matMenu" (closed)="menuOpen = !menuOpen">
        <a mat-menu-item href="https://himosgolf.fi/" target="_blank"
          >Himos Golf<mat-icon iconPositionEnd>launch</mat-icon></a
        >
        <a mat-menu-item href="https://himos.fi/" target="_blank"
          >Himos Ski<mat-icon iconPositionEnd>launch</mat-icon></a
        >
        <a mat-menu-item href="https://hirvikartano.fi/" target="_blank"
          >Ravintola Hirvikartano<mat-icon iconPositionEnd>launch</mat-icon></a
        >
        <a
          mat-menu-item
          href="https://himosravintolat.fi/willilansi/"
          target="_blank"
          >Willi Länsi<mat-icon iconPositionEnd>launch</mat-icon></a
        >
        <a mat-menu-item href="https://himoslomat.fi/tekemista/" target="_blank"
          >Aktiviteetit<mat-icon iconPositionEnd>launch</mat-icon></a
        >
      </mat-menu>
      <a mat-list-item (click)="drawer.toggle()" routerLink="/kuvat">Kuvat</a>
      <a mat-list-item (click)="drawer.toggle()" routerLink="/otayhteytta"
        >Ota yhteyttä</a
      >
      <a mat-list-item (click)="drawer.toggle()" routerLink="/varauskalenteri"
        >Kalenteri</a
      >
      <a *ngIf="isLoggedIn" mat-list-item (click)="logout(); drawer.toggle()"
        >Kirjaudu ulos</a
      >
      <a *ngIf="!isLoggedIn" mat-list-item (click)="login(); drawer.toggle()"
        >Kirjaudu sisään</a
      >
      <a *ngIf="isAdmin" mat-list-item (click)="openSettings(); drawer.toggle()"
        >Asetukset</a>
    </mat-nav-list>
  </mat-drawer>
  <div style="min-height: calc(100% - 56px);" mat-typography>
    <router-outlet />
  </div>
  <app-footer></app-footer>
</mat-drawer-container>
