<div
  class="mat-typography container w-screen max-w-full"
  style="margin-top: -140px"
>
  <div class="md:columns-2 columns-1 gap-0">
    <div
      *ngIf="windowWidth >= m"
      class="w-full h-screen flex flex-wrap text-center flex-column"
      style="background-color: rgb(136, 132, 118)"
    >
      <div class="flex items-end lg:pt-10">
        <h1
          class="my-12 text-3xl lg:text-4xl 2xl:text-5xl font-bold text-white font-serif"
        >
          TYÖHYVINVOINTIA RAKENTAMASSA
        </h1>
      </div>
      <div class="flex items-start justify-center w-full">
        <p class="text-m text-white font-serif">
          “Enemmän kuin perinteinen liikuntaseteli tai toimistolle tuodut
          hedelmät”
        </p>
      </div>
    </div>
    <div
      class="w-full bg-cover h-screen flex flex-wrap text-center flex-column justify-center"
      style="background-image: url('assets/images/mokki_1/etusivu.webp')"
    >
      <div *ngIf="windowWidth < m" class="flex items-end">
        <h1 class="my-12 text-2xl sm:text-5xl font-bold text-white font-serif">
          TYÖHYVINVOINTIA RAKENTAMASSA
        </h1>
      </div>
      <div *ngIf="windowWidth < m" class="flex items-start text-center w-full">
        <p class="text-lg md:text-xl text-white font-serif">
          “Enemmän kuin perinteinen liikuntaseteli tai toimistolle tuodut
          hedelmät”
        </p>
      </div>
    </div>
  </div>
  <div class="flex justify-center items-center h-2/5 bg-gray-200 p-4">
    <div class="w-screen mx-auto bg-white p-6">
      <h1 class="text-4xl font-semibold leading-tight mb-6">
        Hotellitasoista luksusta luonnon keskellä
      </h1>
      <p class="mb-4">
        Mökkimme sijaitsee Himoksen uudella golf-resortin alueella, joka tarjoaa
        ensiluokkaiset puitteet rentoutumiseen ja virkistäytymiseen.
      </p>
      <p class="mb-4">
        Mökkien moderni varustelutaso sekä tyylikäs sisustus tarjoavat
        viihtyisät olosuhteet niin työntekoon kuin vapaa-ajan viettoon.
      </p>
    </div>
  </div>
  <div
    class="mx-auto px-4 sm:px-6 lg:px-8 py-12"
    style="background-color: rgb(180, 170, 150)"
  >
    <h1 class="text-3xl font-bold text-gray-800 text-center mb-10">
      Kuinka palvelu toimii - miten etuus syntyy
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <app-feature-card
        *ngFor="let feature of features"
        [imageSrc]="feature.imageSrc"
        [subtitle]="feature.subtitle"
        [text]="feature.text"
        [color]="feature.color"
      ></app-feature-card>
    </div>
  </div>
  <div class="flex">
    <div
      *ngIf="windowWidth >= m"
      class="w-1/2 bg-cover bg-center"
      style="background-image: url('assets/images/golf.jpeg')"
    ></div>
    <div
      class="w-full my-12 md:w-1/2 flex flex-col justify-center px-6 md:px-20"
    >
      <h1 class="text-2xl font-bold mb-4">
        Aktiviteetit osana palvelupakettia
      </h1>
      <p class="text-lg mb-4 text-gray-600">
        Himos tunnetaan monipuolisista vapaa-ajan mahdollisuuksistaan ympäri
        vuoden. Mökkivuokraukseen on mahdollista sisällyttää erilaisia
        aktiviteettietuja, jotka tuovat lisäarvoa yöntekijöiden vapaa-aikaan.
      </p>
      <ul class="text-base list-disc list-outside space-y-4 mb-4 ml-6 text-gray-600">
        <li>Hissiliput talvikauden laskettelijoille ja lumilautailijoille</li>
        <li>
          Golfpelioikeudet kesän harrastajille Himoksen upeilla golfkentillä
        </li>
        <li>
          Lähialueen retkeily- ja ulkoiluaktiviteetit, kuten pyöräily ja vaellus
        </li>
      </ul>
      <p class="text-base text-gray-600">
        Olipa vuodenkaika mikä tahansa – Himoksella löytyy jokaiselle jotakin.
      </p>
    </div>
  </div>
  <div
    class="mx-auto px-4 sm:px-6 lg:px-8 py-12"
    style="background-color: rgb(180, 170, 150)"
  >
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <h1 class="text-3xl font-bold text-black mb-3">
          Hallinnoitu varauskalenteri – yksinkertaista ja vaivatonta
        </h1>
        <p class="text-black font-normal text-base mb-2">
          Yrityksenne ei tarvitse huolehtia käytännön järjestelyistä.
        </p>
        <p class="text-black font-normal text-base mb-2">
          Tarjoamme mökille hallinnoidun varauskalenterin, joka mahdollistaa
          helpon ja tehokkaan varausjärjestelmän yrityksellenne.
        </p>
        <p class="text-black font-normal text-base mb-2">
          Kalenteri on räätälöity niin, että käyttäjät voivat helposti tarkistaa
          mökin saatavuuden ja tehdä varauksen vaivattomasti.
        </p>
        <p class="text-black font-normal text-base">
          Näin yrityksenne hallinto voi keskittyä olennaiseen, ja mökin
          käyttöönotto sujuu saumattomasti.
        </p>
      </div>
      <div
        *ngIf="windowWidth > m"
        class="flex justify-center w-full"
        style="
          background-image: url('assets/images/booking.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        "
      ></div>
    </div>
  </div>
  <div class="bg-white">
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div *ngIf="windowWidth > m" class="relative">
        <img
          src="assets/images/palapeli.webp"
          alt="Placeholder image depicting a jigsaw puzzle piece fitting into its place, symbolizing solutions or fitting in."
          class="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      <div class="py-16 px-4 space-y-4">
        <h2 class="text-3xl font-semibold text-gray-800">
          Työhyvinvointia uudella tavalla
        </h2>
        <p class="text-base text-gray-600">
          Nykyajan hektisessä elämässä työnantajat etsivät yhä useammin uusia
          keinoja tarjota työntekijöilleen mahdollisuutta palautumiseen ja
          virkistäytymiseen.
        </p>
        <p class="text-base text-gray-600">
          Luksusmökin vuokraus Himoksella tarjoaa yrityksellenne
          työhyvinvoitiedun, joka on enemmän kuin perinteinen liikuntaseteli tai
          toimistolle tuodut hedelmät.
        </p>
        <h2 class="text-3xl font-semibold text-gray-800">
          Tehokas tapa sitouttaa ja palkita henkilöstöä
        </h2>
        <p class="text-base text-gray-600">
          Mökki on loistava tapa palkita parhaiten suoriutuneita työntekijöitä
          tai järjestää yrityksen virkistys- ja tiimipäiviä.
        </p>
        <p class="text-base text-gray-600">
          Se on myös erinomainen tapa erottua markkinoilla, houkutella uusia
          huippuosaajia sekä sitouttaa nykyistä henkilöstöä.
        </p>
      </div>
    </div>
  </div>
  <div
    class="w-screenmx-auto px-4 text-white"
    style="background-color: rgb(136, 132, 118)"
  >
    <h1 class="text-3xl font-bold py-12">KarhuProperties Oy</h1>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div>
        <h2 class="text-xl font-bold mb-4">
          YRITTÄJYYTTÄ JA ASIAKASPALVELUA PORILAISITTAIN
        </h2>
        <p class="mb-4">
          Haluamme mukautua asiakkaidemme toiveisiin ja odotuksiin, kehittäen
          palveluamme vastaamaan alati muuttuvia työelämän ja vapaa-ajan
          vaatimuksia. Meille on tärkeää, että voimme tarjota
          asiakasyrityksillemme yksilöllisiä, mutta samalla joustavia
          palveluratkaisuja.
        </p>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <h2 class="oswald text-2xl font-serif text-center">Yhteistyökumppanit</h2>
        <img
          src="assets/images/logot/bemaster-logo.webp"
          alt="Be-Master"
          class="w-2/3 ml-auto"
        />
        <img
          src="assets/images/logot/voittamaton-logo.png"
          alt="Voittamaton Oy"
          class="w-2/3 mr-auto"
        />
        <img
          src="assets/images/logot/bepop-logo.webp"
          alt="BePop"
          class="w-2/3 ml-auto"
        />
      </div>
    </div>
    <div class="w-2/3 grid grid-cols-1 md:grid-cols-3 gap-8 py-8">
      <div>
        <h3 class="font-semibold mb-2">
          <a [routerLink]="['/rekisteriseloste']">Rekisteriseloste</a>
        </h3>
      </div>
      <div>
        <h3 class="font-semibold mb-2">
          <a [routerLink]="['/varausehdot']">Varausehdot</a>
        </h3>
      </div>
      <div>
        <h3 class="font-semibold mb-2">Ota yhteyttä</h3>
        <p><a [href]="'mailto:' + 'karhuproperties@gmail.com'">karhuproperties&#64;gmail.com</a></p>
        <p><a [href]="'mailto:' + 'asiakaspalvelu@karhuproperties.fi'">asiakaspalvelu&#64;karhuproperties.fi</a></p>
      </div>
    </div>
  </div>
</div>
