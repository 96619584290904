import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { authGuard, roleGuard } from './components/guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { MonthViewComponent } from './components/month-view/month-view.component';
import { KuvatComponent } from './components/kuvat/kuvat.component';
import { VarausehdotComponent } from './components/varausehdot/varausehdot.component';
import { RekisteriselosteComponent } from './components/rekisteriseloste/rekisteriseloste.component';
import { SettingsComponent } from './components/settings/settings.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, title: 'Koti'},
  { path: 'tietoa', component: AboutComponent, title: 'Tietoa'},
  { path: 'otayhteytta', component: ContactComponent, title: 'Ota yhteyttä'},
  { path: 'varauskalenteri', component: MonthViewComponent, title: 'Kalenteri', canActivate: [authGuard]},
  { path: 'kuvat', component: KuvatComponent, title: 'Kuvat'},
  { path: 'kirjaudu', component: LoginComponent, title: 'Kirjaudu sisään'},
  { path: 'varausehdot', component: VarausehdotComponent, title: 'Varausehdot'},
  { path: 'rekisteriseloste', component: RekisteriselosteComponent, title: 'Rekisteriseloste'},
  { path: 'asetukset', component: SettingsComponent, title: 'Asetukset', canActivate: [authGuard, roleGuard]},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
