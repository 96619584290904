import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { ReservationDay } from '../../model/reservationDay';
import { Helper } from '../../util/helper';
import { share } from 'rxjs';

@Component({
  selector: 'app-day',
  standalone: true,
  imports: [MatCardModule, MatGridListModule, DatePipe],
  templateUrl: './day.component.html',
  styleUrl: './day.component.scss',
})
export class DayComponent implements OnInit {
  dateString: string = '';
  weekDayString: string = '';
  @Input() date!: ReservationDay;
  @Output() dateSelected = new EventEmitter<ReservationDay>();

  ngOnInit(): void {
    this.dateString = window.innerWidth > 850 ? Helper.getDateString(this.date.day) : Helper.getDateString(this.date.day, 'x-short');
    this.weekDayString = window.innerWidth > 850 ? Helper.getWeekDayString(this.date.day) : Helper.getWeekDayString(this.date.day, 'short');
  }

  selectDate() {
    this.dateSelected.emit(this.date as ReservationDay);
  }

  getDateString = Helper.getDateString;
  getWeekDayString = Helper.getWeekDayString;
}
