import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ElamyksetModalComponent } from '../components/elamykset-modal/elamykset-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: MatDialog) {}

  openElamyksetModal(): Observable<any> {
    const dialogRef = this.dialog.open(ElamyksetModalComponent, {
      width: window.innerWidth > 500 ? '90%' : '90%',
      autoFocus: false,
      maxHeight: '90vh',
    })

    return dialogRef.afterClosed()
  }
}
