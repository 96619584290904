export interface Reservation {
  startDate: string;
  endDate: string;
  target: string;
  user: ReservationUser;
  id?: string
}

export interface ReservationUser {
  email: string;
  phoneNumber?: string;
  role: string;
}

export interface LoginUser {
  username: string;
  password: string;
  role: string;
  targets: string[];
}

export interface Config {
  [key: string]: any;
}

export interface ApiResponse {
  status: Status;
  message?: string;
  data?: any;
}

export enum Status {
  OK = "OK",
  ERROR = "ERROR",
}