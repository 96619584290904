<mat-card class="container-center-column">
    <mat-card-title class="center">Elämykset</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="center">
        <p>Sisältö tulossa!</p>
        <div class="two-column">
            <p>Sisältö tulossa!</p>
            <img src="/assets/images/mokki_1/mokki_1_9_thumbnail.png" alt="elämykset" class="image"/>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="close()">Sulje</button>
    </mat-card-actions>
</mat-card>
