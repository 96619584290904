import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { SnackbarService } from '../../services/snackbar.service';
import { merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  form: FormGroup;
  userNameControl: FormControl = new FormControl('', [Validators.required]);
  passwordControl: FormControl = new FormControl('', [Validators.required]);
  userNameErrorMessage: string = '';
  passwordErrorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackbar: SnackbarService
  ) {
    this.form = this.fb.group({
      userName: this.userNameControl,
      password: this.passwordControl,
    });

    merge(this.userNameControl.valueChanges, this.passwordControl.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.updateErrorMessages();
      });
  };

  async login() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.updateErrorMessages();
    }
    const val = this.form.value;

    if (val.userName && val.password) {
      await this.authService.login(val.userName, val.password);
      if (this.authService.isLoggedIn()) {
        this.snackbar.openSnackBar('Kirjautuminen onnistui');
        this.router.navigateByUrl('/varauskalenteri');
      } else {
        this.snackbar.openSnackBar('Kirjautuminen epäonnistui');
        this.router.navigateByUrl('/kirjaudu');
      }
    }
  }

  updateErrorMessages() {
    if (this.userNameControl.hasError('required')) {
      this.userNameErrorMessage = 'Käyttäjätunnus on pakollinen';
    } else {
      this.userNameErrorMessage = '';
      this.userNameControl.setErrors(null);
    }

    if (this.passwordControl.hasError('required')) {
      this.passwordErrorMessage = 'Salasana on pakollinen';
    } else {
      this.passwordErrorMessage = '';
      this.passwordControl.setErrors(null);
    }
  }
}
