import { CanActivateFn, Router } from '@angular/router';

// Assuming you have some service to manage JWT token verification
import { AuthService } from '../../services/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isLoggedIn()) {
    return true;
  } else {
    return router.parseUrl("/kirjaudu");
  }
};

export const roleGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isUserInRole('ADMIN')) {
    return true;
  } else {
    return router.parseUrl("/");
  }
};