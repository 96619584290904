import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { Helper } from '../../util/helper';
import { DateAdapter } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DateModalComponent } from '../date-modal/date-modal.component';
import { MatDividerModule } from '@angular/material/divider';
import { DayComponent } from '../day/day.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ApiResponse, Config, Reservation, Status } from '../../../../../shared/interface';
import moment from 'moment';
import { ReservationDay } from '../../model/reservationDay';
import { VarausService } from '../../services/varaus.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { ConfigService } from '../../services/config.service';
import { CONFIG_KEYS } from '../../../../../shared/constants';
import { SnackbarService } from '../../services/snackbar.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-month-view',
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    DayComponent,
    MatGridListModule,
    MatProgressSpinnerModule,
    FormsModule,
  ],
  templateUrl: './month-view.component.html',
  styleUrl: './month-view.component.scss',
})
export class MonthViewComponent implements OnInit {
  getYearString = Helper.getYearString;
  getMonthString = Helper.getMonthString;
  isValidSelection = Helper.isValidSelection;
  isInCurrentMonth = Helper.isInCurrentMonth;
  isPast = Helper.isPast;
  equalsIgnoreTime = Helper.equalsIgnoreTime;
  getToday = Helper.getToday;

  reservations: Reservation[] = [];

  disablePrevious?: boolean;
  disableNext?: boolean;
  selectedDate: moment.Moment;
  currentViewMonth: moment.Moment;
  view: 'month' | 'week' = 'month';
  today: moment.Moment = this.getToday();
  month: ReservationDay[][] = [];
  loading = false;
  fetchFailed = false;
  config: Config[] = [];
  minDate: moment.Moment = moment();
  maxDate: moment.Moment = moment().add(2, 'year');

  constructor(
    private _adapter: DateAdapter<Date>,
    public dialog: MatDialog,
    private varausService: VarausService,
    private configService: ConfigService,
    private snackbar: SnackbarService,
    private modalService : ModalService
  ) {
    this.selectedDate = this.today;
    this.currentViewMonth = this.today;
    this._adapter.setLocale('fi-FI');
    this.configService.getConfigValue(CONFIG_KEYS.MIN_DATE_BUFFER_MONTHS).subscribe(val => this.minDate = moment().add(val, 'months'));
    this.configService.getConfigValue(CONFIG_KEYS.MAX_DATE_BUFFER_MONTHS).subscribe(val => this.maxDate = moment().add(val, 'months'));
  }

  ngOnInit() {
    this.generateCalendar(this.today, true);
  }

  async generateCalendar(date: moment.Moment, refreshReservations = false) {
    this.loading = true;
    try {
      if (refreshReservations) {
        this.reservations = await this.varausService.getReservations();
      }
    } catch (error) {
      this.fetchFailed = true;
      this.loading = false;
      return;
    }

    // Use moment for date manipulation
    const startOfMonth = moment(date).startOf('month');
    const endOfMonth = moment(date).endOf('month');

    // Calculate adjustments to include full weeks
    const startDayAdjustment =
      startOfMonth.day() === 0 ? 6 : startOfMonth.day() - 1;
    const endDayAdjustment = endOfMonth.day() === 0 ? 0 : 7 - endOfMonth.day();

    // Adjusted start and end dates to ensure full weeks
    const adjustedStartOfMonth = moment(startOfMonth).subtract(
      startDayAdjustment,
      'days'
    );
    const adjustedEndOfMonth = moment(endOfMonth).add(endDayAdjustment, 'days');
    // Total days to generate, including adjustments
    const dayCount = adjustedEndOfMonth.diff(adjustedStartOfMonth, 'days') + 1;

    // Initialize calendar structure
    let currentDay = moment(adjustedStartOfMonth);
    this.currentViewMonth = moment(date).startOf('month');
    this.month = [];

    for (let i = 0; i < dayCount; i++) {
      const weekIndex = Math.floor(i / 7);
      if (!this.month[weekIndex]) {
        this.month[weekIndex] = [];
      }

      let isReserved = this.isReserved(currentDay);

      this.month[weekIndex].push({
        day: currentDay.clone(), // Clone to create a new moment object
        isMorningReserved: isReserved.morningReserved,
        isEveningReserved: isReserved.eveningReserved,
        isToday: currentDay.isSame(this.today),
        isPast: this.isPast(currentDay),
        isSelected: this.selectedDate.isSame(currentDay),
        isCurrentMonth: this.isInCurrentMonth(currentDay, date),
        selectable: this.isValidSelection(currentDay, this.maxDate, this.minDate),
      } as ReservationDay);

      currentDay.add(1, 'days'); // This modifies the currentDay moment object
    }

    this.checkDisablePreviousNext();
    this.loading = false;
  }

  changeMonth(direction: number) {
    this.currentViewMonth = moment(this.currentViewMonth)
      .add(direction, 'month')
      .startOf('month');
    this.generateCalendar(this.currentViewMonth);
  }

  async onDateSelected(event: { value: Date | ReservationDay }) {
    let reservationDay: ReservationDay | undefined;
    if (this.isReservationDay(event.value)) {
      reservationDay = event.value;
    } else {
      const date = moment(event.value);
      if (!this.isInCurrentMonth(date, this.currentViewMonth)) {
        await this.generateCalendar(date, true);
      }

      reservationDay = this.month
        .flat()
        .find((day) => this.equalsIgnoreTime(day.day, date));
      if (!reservationDay) {
        return;
      }
    }
    this.selectDay(reservationDay);
  }

  isReservationDay(day: any): day is ReservationDay {
    return day && day.selectable !== undefined;
  }

  selectDay(date: ReservationDay, generateCalendar = true) {
    if (!date.selectable) {
      this.snackbar.openSnackBar('Päivä ei ole valittavissa');
      return;
    }
    this.openModal(date);
    this.selectedDate = this.currentViewMonth = date.day;
    if (generateCalendar) {
      this.generateCalendar(date.day);
    }
  }
  openModal(date: ReservationDay) {
    const dialogRef = this.dialog.open(DateModalComponent, {
      width: window.innerWidth > 500 ? '50%' : '99%',
      data: {
        selectedDate: date,
        reservations: this.reservations,
      },
    })

    dialogRef.afterClosed().subscribe(async (result: {isSubmit: boolean, response: ApiResponse}) => {
      if (!result || !result.isSubmit) {
        return;
      }
      if (result.response.status === Status.OK) {
        this.generateCalendar(this.selectedDate, true);
        const text = result.response.message;
        if (text === 'Reservation successful') {
          this.snackbar.openSnackBar('Varaus onnistui. Saat sähköpostiisi vahvistusviestin.')
        } else if (text === 'Failed to send confirmation emails') {
          this.snackbar.openSnackBar('Varaus onnistui, mutta varausvahvistuksen sähköpostiin lähettäminen epäonnistui');
        } else if (text === 'Reservation deleted') {
          this.snackbar.openSnackBar('Varaus poistettu');
        } 
      } else {
        this.snackbar.openSnackBar('Varaus epäonnistui. Jos virhe toistuu, ole yhteydessä palveluntarjoajaan.');
      }
    });
  }

  checkDisablePreviousNext() {
    this.disablePrevious = this.currentViewMonth
      .startOf('day')
      .isSameOrBefore(this.minDate);
    this.disableNext = this.currentViewMonth
      .startOf('day')
      .isSameOrAfter(this.maxDate);
  }

  isReserved(date: moment.Moment): {
    morningReserved: boolean;
    eveningReserved: boolean;
  } {
    let morningReserved = false;
    let eveningReserved = false;
    const targetDate = moment(date).startOf('day');

    // Check each reservation once, calculating start and end of the day only once
    this.reservations.forEach((reservation) => {
      const startDate = moment(reservation.startDate).startOf('day');
      const endDate = moment(reservation.endDate).startOf('day');

      // If the target date is the same as start date, mark evening as reserved
      if (targetDate.isSame(startDate)) {
        eveningReserved = true;
      }

      // If the target date is the same as end date, mark morning as reserved
      if (targetDate.isSame(endDate)) {
        morningReserved = true;
      }

      // If the target date is strictly between the start and end, both are reserved
      if (targetDate.isAfter(startDate) && targetDate.isBefore(endDate)) {
        morningReserved = true;
        eveningReserved = true;
      }
    });

    return { morningReserved, eveningReserved };
  }
}
