import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthService } from '../../services/auth.service';
import { MatInputModule } from '@angular/material/input';
import { LoginUser, Status } from '../../../../../shared/interface';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarService } from '../../services/snackbar.service';

interface User {
  username: string;
  password: string;
  targets: string[];
  role: string;
}

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [MatCardModule, FormsModule, CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule, MatSelectModule, MatIconModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {

  users?: User[];
  selectedUser?: User;
  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });
  usernameErrorMessage = '';
  passwordErrorMessage = '';


  constructor(private authService: AuthService, private snackbar: SnackbarService) { }

  async ngOnInit() {
    this.users = (await this.authService.fetchUsers(this.authService.getUsername())).data;
  }

  async submit() {
    if (this.form.invalid) {
      this.updateErrorMessages();
      this.form.markAllAsTouched();
      return;
    }
    const response = await this.authService.updateUserdata(this.selectedUser!.username, this.form.controls.username.value!, this.form.controls.password.value!);

    if (response.status !== Status.OK) {
      this.snackbar.openSnackBar('Käyttäjän tietojen päivitys epäonnistui');
    } else {
      this.snackbar.openSnackBar('Käyttäjän tiedot päivitetty');
    }
    
    this.selectedUser = undefined;
    this.users = (await this.authService.fetchUsers(this.form.controls.username.value!)).data;
    this.form.reset();
  }

  updateErrorMessages() {
    if (this.form.controls.username.hasError('required')) {
      this.usernameErrorMessage = 'Käyttäjänimi on pakollinen';
    }
    if (this.form.controls.password.hasError('required')) {
      this.passwordErrorMessage = 'Salasana on pakollinen';
    }
    if (this.form.controls.password.hasError('minlength')) {
      this.passwordErrorMessage = 'Salasanan pituus on oltava vähintään 8 merkkiä';
    }
  }

  onUserChange(event: MatSelectChange) {
    this.selectedUser = event.value;
    this.form.controls.username.setValue(this.selectedUser!.username);
  }
}
