<mat-card
  style="height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;"
  [class.gray-out]="!date.isCurrentMonth"
  [class.dark-gray-out]="date.isPast"
  [class.click-disabled]="!date.selectable"
  [class.selected]="date.isSelected"
  [class.today]="date.isToday"
  [class.reserved-morning]="date.isMorningReserved"
  [class.reserved-evening]="date.isEveningReserved"
  [class.reservable-morning]="!date.isMorningReserved"
  [class.reservable-evening]="!date.isEveningReserved"
  (click)="selectDate()"
>
  <mat-card-title>{{  weekDayString }}</mat-card-title>
  <mat-card-subtitle>{{ dateString }}</mat-card-subtitle>
</mat-card>
