import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
  selector: 'app-varausehdot',
  standalone: true,
  imports: [MatCardModule, MatDividerModule],
  templateUrl: './varausehdot.component.html',
  styleUrl: './varausehdot.component.scss'
})
export class VarausehdotComponent {
  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }
}
