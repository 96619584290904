// logger.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private apiUrl = environment.production ? '/api/log' : 'http://localhost:3000/api/log';

  constructor(private http: HttpClient) {}

  private sendLog(message: string, level: string, ...args: any[]) {
    this.http.post(this.apiUrl, { message, level, args }).subscribe({
      error: (err) => console.error('Failed to send log to server', err),
    });
  }

  info(message: string) {
    this.sendLog(message, 'info');
  }

  error(message: string, ...args: any[]) {
    this.sendLog(message, 'error', args);
  }

  warn(message: string) {
    this.sendLog(message, 'warn');
  }

  debug(message: string) {
    this.sendLog(message, 'debug');
  }
}
