import { Injectable } from '@angular/core';
import { ApiResponse, Reservation } from '../../../../shared/interface';
import { reservationApi } from '../../api/kalenteri-api';
import { LoggerService } from './logger.service';
import moment from 'moment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class VarausService {
  constructor(private logger: LoggerService, private authService: AuthService) {}

  async getReservations(minDate: moment.Moment = moment()): Promise<Reservation[]> {
    try {
      const reservations = await reservationApi.fetchVaraukset(minDate.format('YYYY-MM-DD'), this.authService.getTargets()[0]);
      this.logger.info('Reservations fetched:' + reservations.data.length);

      if (!this.authService.isUserInRole('ADMIN') && reservations.data.length > 0) {
        reservations.data = reservations.data.map((r: Reservation) => ({
          startDate: r.startDate,
          endDate: r.endDate,
          id: r.id,
        }));
      }
      return reservations.data;
    } catch (error) {
      this.logger.error('Error fetching reservations', error);
      throw error;
    }
  }

  async createReservation(reservation: Reservation): Promise<ApiResponse> {
    try {
      const response = await reservationApi.createVaraus(reservation);
      this.logger.info('Reservation created:'+ response.message);
      return response;
    } catch (error) {
      this.logger.error('Error creating reservation', error);
      throw error;
    }
  }

  async deleteReservation(id: string): Promise<ApiResponse> {
    try {
      const response = await reservationApi.deleteVaraus(id);
      this.logger.info('Reservation deleted:' + response.message);
      return response;
    } catch (error) {
      this.logger.error('Error deleting reservation', error);
      throw error;
    }
  }
}
