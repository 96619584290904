<div class="container-center-column">
    <mat-card style="min-width: 40%">
        <mat-card-actions *ngIf="selectedUser">
            <button mat-icon-button color="primary" (click)="selectedUser = undefined"><mat-icon matTextPrefix>arrow_back</mat-icon></button>
        </mat-card-actions>
        <mat-card-header>
            <mat-card-title>Valitse käyttäjä, jota haluat muokata</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="width: 100%;">
                <mat-label>Käyttäjä</mat-label>
                <mat-select [(value)]="selectedUser" (selectionChange)="onUserChange($event)">
                    <mat-option *ngFor="let user of users" [value]="user">{{ user.username }} <span>({{ user.targets }})</span></mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <ng-container *ngIf="selectedUser">
            <mat-card-header> 
                <mat-card-title>Vaihda käyttäjän "{{ selectedUser.username }}" salasanaa ja/tai käyttäjänimeä</mat-card-title>
                <mat-card-subtitle>Jos et halua muuttaa salasanaa tai käyttäjänimeä, syötä nyt käytössä oleva arvo</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="form" (ngSubmit)="submit()"  class="container-center-column">
                    <mat-form-field style="width: 100%;">
                        <input matInput placeholder="Uusi käyttäjänimi" formControlName="username">
                        <mat-error *ngIf="form.controls.username.invalid">{{ usernameErrorMessage }}</mat-error>
                    </mat-form-field>
                    <mat-form-field style="width: 100%;">
                        <input matInput placeholder="Uusi salasana" formControlName="password" type="password">
                        <mat-error *ngIf="form.controls.password">{{ passwordErrorMessage }}</mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions [align]="'end'">
                <button mat-raised-button color="primary" (click)="
                    submit();
                ">Tallenna</button>
            </mat-card-actions>
        </ng-container>
    </mat-card>
</div>