import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'maxReservationDate',
  standalone: true
})
export class MaxReservationDatePipe implements PipeTransform {

  transform(freeDates: moment.Moment[], selectedDate: moment.Moment): moment.Moment {
    if (!freeDates || freeDates.length === 0) {
      return selectedDate.add(-1, 'day');
    }

    return freeDates.reduce((maxDate, date) => {
      return date.isAfter(maxDate) ? date : maxDate;
    }, selectedDate);
  }

}
