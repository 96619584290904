import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDividerModule],
})
export class FeatureCardComponent implements OnInit, AfterContentInit {

  constructor(private cdr: ChangeDetectorRef) {
    
  }

  @Input() imageSrc: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() text: string = '';
  @Input() color!: string;
  @ViewChild('content') content!: ElementRef<HTMLDivElement>;

  height: number = 0;

  protected texts!: string[];

  ngOnInit() {
    this.texts = this.text.split('$').filter(t => t.trim() != '');
    console.log(this.texts)

    window.addEventListener('resize', this.onResize.bind(this));
  }

  ngAfterContentInit(): void {
   setTimeout(() => {
    this.height = this.content?.nativeElement.offsetHeight
   })
  }

  onResize(_event: any) {
    this.height = this.content?.nativeElement.offsetHeight
    this.cdr.detectChanges()
  }
}
