<mat-dialog-content class="center" style="padding: 0; border: 2px solid gray">
  <mat-card>
    <mat-card-title class="center">{{ weekDayString }}</mat-card-title>
    <mat-card-title mat-dialog-title class="center">
      {{ dateString }}
    </mat-card-title>
    <mat-divider></mat-divider>
    <ng-container *ngIf="isAdmin; else user">
      <mat-card-title class="center">Päivän varaustiedot</mat-card-title>
      <mat-card-content>
        <ng-container
          *ngIf="
            !reservationsOnDate?.during &&
              !reservationsOnDate?.starting &&
              !reservationsOnDate?.ending;
            else isReservations
          "
        >
          <mat-card-title>Ei varauksia valittuna päivänä</mat-card-title>
        </ng-container>
        <ng-template #isReservations>
          <div *ngIf="reservationsOnDate">
            <mat-list>
              <div class="reservation-info" *ngIf="reservationsOnDate.during">
                <mat-list-item>
                  <span matListItemTitle
                    >Varaus on voimassa valittuna päivänä. Varaajan
                    tiedot:</span
                  >
                  <span matListItemLine>
                    Sähköpostiosoite:
                    <a
                      [href]="'mailto:' + reservationsOnDate.during.user.email"
                      >{{ reservationsOnDate.during.user.email }}</a
                    >
                  </span>
                  <span matListItemLine>
                    Puhelinnumero:
                    {{ reservationsOnDate.during.user.phoneNumber }}
                  </span>
                  <span matListItemLine>
                    Varaus voimassa:
                    {{
                      reservationsOnDate.during.startDate | date : "d.M.yyyy"
                    }}
                    -
                    {{ reservationsOnDate.during.endDate | date : "d.M.yyyy" }}
                  </span>
                </mat-list-item>
                <button
                  mat-flat-button
                  style="margin: 16px 16px"
                  color="warn"
                  (click)="deleteReservation(reservationsOnDate.during.id!)"
                >
                  Poista varaus
                </button>
              </div>
              <div class="reservation-info" *ngIf="reservationsOnDate.starting">
                <mat-list-item>
                  <span matListItemTitle
                    >Varaus alkaa valittuna päivänä. Varaajan tiedot:</span
                  >
                  <span matListItemLine>
                    Sähköpostiosoite:
                    <a
                      [href]="
                        'mailto:' + reservationsOnDate.starting.user.email
                      "
                      >{{ reservationsOnDate.starting.user.email }}</a
                    >
                  </span>
                  <span matListItemLine>
                    Puhelinnumero:
                    {{ reservationsOnDate.starting.user.phoneNumber }}
                  </span>
                  <span matListItemLine>
                    Varaus voimassa:
                    {{
                      reservationsOnDate.starting.startDate | date : "d.M.yyyy"
                    }}
                    -
                    {{
                      reservationsOnDate.starting.endDate | date : "d.M.yyyy"
                    }}
                  </span>
                </mat-list-item>
                <button
                  mat-flat-button
                  style="margin: 16px 16px"
                  color="warn"
                  (click)="deleteReservation(reservationsOnDate.starting.id!)"
                >
                  Poista varaus
                </button>
              </div>
              <div class="reservation-info" *ngIf="reservationsOnDate.ending">
                <mat-list-item>
                  <span matListItemTitle
                    >Varaus päättyy valittuna päivänä. Varaajan tiedot:</span
                  >
                  <span matListItemLine>
                    Sähköpostiosoite:
                    <a
                      [href]="'mailto:' + reservationsOnDate.ending.user.email"
                      >{{ reservationsOnDate.ending.user.email }}</a
                    >
                  </span>
                  <span matListItemLine>
                    Puhelinnumero:
                    {{ reservationsOnDate.ending.user.phoneNumber }}
                  </span>
                  <span matListItemLine>
                    Varaus voimassa:
                    {{
                      reservationsOnDate.ending.startDate | date : "d.M.yyyy"
                    }}
                    -
                    {{ reservationsOnDate.ending.endDate | date : "d.M.yyyy" }}
                  </span>
                </mat-list-item>
                <button
                  mat-flat-button
                  style="margin: 16px 16px"
                  color="warn"
                  (click)="deleteReservation(reservationsOnDate.ending.id!)"
                >
                  Poista varaus
                </button>
              </div>
            </mat-list>
          </div>
        </ng-template>
      </mat-card-content>
      <mat-dialog-actions>
        <button mat-raised-button mat-dialog-close color="warn">Sulje</button>
      </mat-dialog-actions>
    </ng-container>
    <ng-template #user>
      <mat-card-title *ngIf="date.isMorningReserved && date.isEveningReserved"
        >Päivä varattu</mat-card-title
      >
      <mat-card-title *ngIf="date.isEveningReserved && !date.isMorningReserved"
        >Iltapäivä varattu</mat-card-title
      >
      <div *ngIf="!isEnoughDaysSelected">
        <mat-card-title
          >Valittavissa olevia vapaita päiviä ei ole tarpeeksi päivästä
          {{ dateString }} alkaen.</mat-card-title
        >
        <mat-card-title
          >Päiviä on valittava vähintään {{ minDaysToReserve }} ja vapaita
          päiviä valitusta päivästä alkaen on
          {{ nextFreeDates.length }}.</mat-card-title
        >
      </div>

      <div *ngIf="!date.isEveningReserved && isEnoughDaysSelected">
        <mat-card-title class="center">Varaa päivä</mat-card-title>
        <div [formGroup]="form" class="container-center-column">
          <mat-form-field>
            <mat-label>Sähköposti</mat-label>
            <input
              matInput
              [formControl]="emailFormControl"
              (blur)="updateErrorMessages()"
            />
            <mat-icon matSuffix>mail</mat-icon>
            <mat-error *ngIf="emailFormControl.invalid">{{
              emailErrorMessage
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Puhelinnumero</mat-label>
            <input
              matInput
              type="tel"
              [formControl]="mobileFormControl"
              (blur)="updateErrorMessages()"
            />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-error *ngIf="mobileFormControl.invalid">{{
              mobileErrorMessage
            }}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Päättymispäivä</mat-label>
            <input
              matInput
              [formControl]="endDateFormControl"
              [matDatepicker]="picker"
              [min]="date.day.toDate()"
              [max]="(nextFreeDates | maxReservationDate : date.day).toDate()"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="endDateFormControl.invalid">{{
              endDateErrorMessage
            }}</mat-error>
          </mat-form-field>
          <mat-checkbox
            required
            [formControl]="acceptTermsFormControl"
            >Hyväksyn
            <a href="/varausehdot" target="_blank">varausehdot</a>
          </mat-checkbox>
          <mat-error style="font-size: 12px;" *ngIf="acceptTermsFormControl.invalid">{{
            acceptTermsErrorMessage
          }}</mat-error>
        </div>
        <mat-card-footer>
          <mat-progress-bar
            *ngIf="inProgess"
            mode="indeterminate"
          ></mat-progress-bar>
        </mat-card-footer>
        <mat-dialog-actions [align]="'center'">
          <button mat-raised-button color="accent" (click)="reserve()">
            Varaa
          </button>
          <button mat-raised-button mat-dialog-close color="warn">Sulje</button>
        </mat-dialog-actions>
      </div>
    </ng-template>
  </mat-card>
</mat-dialog-content>
