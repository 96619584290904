import moment from 'moment';
import { Reservation } from '../../../../shared/interface';

export class Helper {
  constructor() {}

  static getToday(): moment.Moment {
    return moment().startOf('day');
  }
  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  static getWeekDayString(day: moment.Moment, mode: 'short' | 'long' = 'long') {
    return mode === 'long' ? Helper.capitalizeFirstLetter(day.format('dddd')) : Helper.capitalizeFirstLetter(day.format('ddd'));
  }
  static getDateString(day: moment.Moment, mode: 'x-short' | 'short' | 'long' = 'long') {
    return mode === 'long' ? day.format('D.M.YYYY') : mode === 'short' ? day.format('D.M.YY') : day.format('D.M');
  }

  static getMonthString(day: moment.Moment) {
    return Helper.capitalizeFirstLetter(day.format('MMMM'));
  }

  static getYearString(day: moment.Moment) {
    return day.format('YYYY');
  }

  static isValidSelection(day: moment.Moment, maxDate: moment.Moment, minDate: moment.Moment) {
    return (
      Helper.getDateIgnoreTime(day).isSameOrAfter(Helper.getDateIgnoreTime(minDate)) &&
      Helper.getDateIgnoreTime(day).isSameOrBefore(Helper.getDateIgnoreTime(maxDate))
    );
  }

  static isInCurrentMonth(day: moment.Moment, currentView: moment.Moment) {
    return day.isSame(currentView, 'month') && day.isSame(currentView, 'year');
  }

  static getDateIgnoreTime(day: moment.Moment) {
    return moment(day).startOf('day');
  }

  static isPast(day: moment.Moment) {
    return day.startOf('day').isBefore(Helper.getToday());
  }

  static equalsIgnoreTime(date1: moment.Moment, date2: moment.Moment) {
    return Helper.getDateIgnoreTime(date1).isSame(Helper.getDateIgnoreTime(date2));
  }

  static isDateInReservation(day: moment.Moment, reservations: Reservation[]) {
    return reservations.some((reservation) => {
      return (
        Helper.getDateIgnoreTime(day).isAfter(Helper.getDateIgnoreTime(moment(reservation.startDate))) &&
        Helper.getDateIgnoreTime(day).isBefore(Helper.getDateIgnoreTime(moment(reservation.endDate)))
      );
    });
  }
}
