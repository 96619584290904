<div class="p-4">
  <div>
    <div
      class="p-3 w-fullflex justify-center bg-contain bg-no-repeat"
      style="background-image: url('{{ imageSrc }}');"
    >
      <img src="{{ imageSrc }}" class="invisible" />
    </div>
    <div class="flex flex-row">
      <div class="divider" [style.height.px]="height" [ngClass]="color">
        <div class="circle" [ngClass]="color"></div>
      </div>
      <div #content>
        <h3 class="font-semibold mt-2">{{ subtitle }}</h3>
        <ul>
          <li
            *ngFor="let listItem of texts; let last = last"
            class="mt-2 text-gray-800"
          >
            {{ listItem }}.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
