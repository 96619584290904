<div class="container-center-column" style="height: 100%;">
    <mat-card>
        <mat-card-header class="container-center-column-top">
            <mat-card-title class="center">Kuvat</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-divider></mat-divider>
            <div *ngFor="let target of targets" class="target-container">
                <mat-card-subtitle class="center">{{ target.name }}</mat-card-subtitle>
                <div class="images">
                    <div class="image-container"
                        *ngFor="let image of target.images; let i = index"
                        [lightbox]="i"
                        [gallery]="target.name">
                        <img [src]="image.data?.thumb" alt="Kuva" />
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
