import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { Config } from '../../../../shared/interface';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configUrl = environment.production ? '/api/config' : 'http://localhost:3000/api/config';
  private config = new BehaviorSubject<Config[]>([]);

  constructor(private http: HttpClient, private loggerService: LoggerService) {
    this.loadInitialConfig();
  }

  private loadInitialConfig() {
    this.http.get<Config[]>(this.configUrl).subscribe({
      next: (response) => {
        this.config.next(response); // Successfully loaded and processed configs
      },
      error: (error) => {
        this.loggerService.error('Failed to load config:', error); // Log error
        this.config.next([]); // Optionally provide a fallback or empty data set
      }
    });
  }

  getConfig(): Observable<Config[]> {
    return this.config.asObservable();
  }

  getConfigValue(key: string): Observable<any> {
    return this.getConfig().pipe(
      map((configs) =>
        Array.isArray(configs)
          ? configs.find((config) => config['key'] === key)?.['value']
          : undefined
      )
    );
  }

  updateConfig(configs: Config[]): Observable<void> {
    return this.http
      .put<void>(this.configUrl, { configs })
      .pipe(tap(() => this.config.next(configs)));
  }
}
