import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { RouterLink } from '@angular/router';
import { ImageCarouselComponent } from '../image-carousel/image-carousel.component';
import { FeatureCardComponent } from "../feature-card/feature-card.component";

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    MatCardModule,
    RouterLink,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    CommonModule,
    ImageCarouselComponent,
    MatExpansionModule,
    FeatureCardComponent
],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent {

  protected m = 768;
  protected l = 1024;
  protected xl = 1280;

  protected features = [
    {
      subtitle: 'Omistaa kohteen ja vastaa sen ylläpidosta ja huollosta',
      imageSrc: 'assets/images/vasen.png',
      text: 'Mahdollistaa asiakkaalle hotellitasoisen mökin palvelut ja aktiviteetit$' +
      'Hallinnoi varauskalentereita ja järjestää siivoukset sekä muut tarvittavat palvelut$',
      color: 'brown'
    },
    {
      subtitle: 'Mahdollistaa henkilöstölleen etuuden vuokrasopimuksella KarhuPropertiesin kanssa',
      imageSrc: 'assets/images/keski.png',
      text: 'Säästää aikaa ja rahaa kohteen hankinnasta sekä jatkuvasta hallinnoinnista ja ylläpidosta, jotka hoidetaan kokonaispalveluna$' + 
      'Käyttää etuja strategisesti osana henkilöstöetupakettia työntekijöiden sitouttamiseksi ja hyvinvoinnin tukemiseksi$',
      color: 'blue'
    },
    {
      subtitle: 'Saa varausoikeuden kohteeseen',
      imageSrc: 'assets/images/oikea.png',
      text: 'Saa käyttöönsä edun, jonka arvo on noin 1700 € viikossa perustason paketilla$' +
      'Voi tehdä ja hallinnoida varauksiaan itsenäisesti$' +
      'Varauksesta nimellinen käyttömaksu yritykselle (esim. viikkovaraus hintaan 199 €)$' +
      'Nauttii palveluista, etuuksista ja vaivattomuudesta$',
      color: 'green'
    }
  ]

  readonly panelOpenState = signal(false);

  windowWidth: number;

  constructor() {
    this.windowWidth = window.innerWidth;
  }

  //Subscribe to window resize event
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }
}
