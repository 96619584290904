import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import moment from 'moment';
import 'moment/locale/fi';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AuthService } from './services/auth.service';
import { SnackbarService } from './services/snackbar.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconButton,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    AppFooterComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private _mobileQueryListener: () => void;
  mobileQuery: MediaQueryList;
  showFiller = false;
  opened: any;
  menuOpen = false;
  isLoggedIn = false;
  isAdmin = false;
  navButtonsWhite: boolean = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private router: Router,
    private snackbar: SnackbarService,
  ) {
    moment.locale('fi');
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.authService.authStatus$.subscribe(status => {
      this.isLoggedIn = status;
      this.isAdmin = this.authService.isUserInRole('ADMIN');
    })

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navButtonsWhite = event.url === '/tietoa' || event.url === '/';
      } 
    });
  }

  logout() {
    if (this.authService.isLoggedIn()) {
      this.authService.logout();
      this.snackbar.openSnackBar('Kirjauduit ulos');
      this.router.navigateByUrl('/kirjaudu');
    }
  }

  login() {
    this.router.navigateByUrl('/kirjaudu');
  }

  openSettings() {
    this.router.navigateByUrl('/asetukset');
  }
}
