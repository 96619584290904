import { CommonModule, DatePipe } from '@angular/common';
import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ReservationDay } from '../../model/reservationDay';
import { Helper } from '../../util/helper';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ConfigService } from '../../services/config.service';
import {
  CONFIG_KEYS,
  emailPattern,
  mobilePattern,
} from '../../../../../shared/constants';
import moment from 'moment';
import { LoggerService } from '../../services/logger.service';
import { MaxReservationDatePipe } from '../../pipes/max-reservation-date.pipe';
import { ApiResponse, Reservation } from '../../../../../shared/interface';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { merge } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { VarausService } from '../../services/varaus.service';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from '../../services/auth.service';
import { MatListModule } from '@angular/material/list';

interface ReservationsOnDate {
  [key: string]: Reservation | undefined;
  starting?: Reservation;
  ending?: Reservation;
  during?: Reservation;
}

@Component({
  selector: 'app-date-modal',
  standalone: true,
  templateUrl: './date-modal.component.html',
  styleUrls: ['./date-modal.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatGridListModule,
    MatCheckboxModule,
    DatePipe,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MaxReservationDatePipe,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressBarModule,
    MatListModule
  ],

})
export class DateModalComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(emailPattern),
  ]);
  mobileFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(mobilePattern),
  ]);
  endDateFormControl = new FormControl('', [Validators.required]);
  acceptTermsFormControl = new FormControl(false, [Validators.required]);
  form: FormGroup = new FormGroup({
    email: this.emailFormControl,
    mobile: this.mobileFormControl,
    endDate: this.endDateFormControl,
    acceptTerms: this.acceptTermsFormControl,
  });

  reservations: Reservation[] = [];
  dateString: string = '';
  weekDayString: string = '';
  date!: ReservationDay;
  minDaysToReserve: number = 1;
  maxDaysToReserve: number = 7;
  mobilePattern = mobilePattern.toString().slice(1, -1);
  emailPattern = emailPattern.toString().slice(1, -1);
  isEnoughDaysSelected: boolean = true;
  nextFreeDates: moment.Moment[] = [];
  emailErrorMessage: string = '';
  mobileErrorMessage: string = '';
  endDateErrorMessage: string = '';
  acceptTermsErrorMessage: string = '';
  inProgess = false;
  isAdmin = false;
  reservationsOnDate: ReservationsOnDate = {};
  reservationTypes = [
    { key: 'during', label: 'Varaus on voimassa valittuna päivänä. Varaajan tiedot:' },
    { key: 'starting', label: 'Varaus alkaa valittuna päivänä. Varaajan tiedot:' },
    { key: 'ending', label: 'Varaus päättyy valittuna päivänä. Varaajan tiedot:' }
  ];

  constructor(
    public dialogRef: MatDialogRef<DateModalComponent>,
    private configService: ConfigService,
    private loggerService: LoggerService,
    private varausService: VarausService,
    private authService: AuthService,
    private _adapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedDate: ReservationDay; reservations: Reservation[] }
  ) {
    this._adapter.setLocale('fi-FI');
    this.configService.getConfig().subscribe(() => {
      this.configService
        .getConfigValue(CONFIG_KEYS.MIN_DAYS_TO_RESERVE)
        .subscribe((minDaysToReserve) => {
          this.minDaysToReserve = minDaysToReserve;
        });
      this.configService
        .getConfigValue(CONFIG_KEYS.MAX_DAYS_TO_RESERVE)
        .subscribe((maxDaysToReserve) => {
          this.maxDaysToReserve = maxDaysToReserve;
        });
    });

    merge(
      this.emailFormControl.valueChanges,
      this.emailFormControl.statusChanges,
      this.mobileFormControl.valueChanges,
      this.mobileFormControl.statusChanges
    )
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.updateErrorMessages();
      });
  }

  ngOnInit(): void {
    this.reservations = this.data.reservations;
    this.date = this.data.selectedDate;
    this.dateString = Helper.getDateString(this.date.day);
    this.weekDayString = Helper.getWeekDayString(this.date.day);
    this.nextFreeDates = this.getNextFreeDates();
    this.isAdmin = this.authService.isUserInRole('ADMIN');
    if (this.isAdmin) {
      this.reservationsOnDate = this.getReservationsOnDate(this.date.day);
    }
  }
  getReservationData(type: string) {
    return this.reservationsOnDate ? this.reservationsOnDate[type] : null;
  }

  getNextFreeDates(): moment.Moment[] {
    const result: moment.Moment[] = [];
    const isReservationStartingFromDay = (day: moment.Moment) => {
      return this.reservations.some((reservation) =>
        day.isSame(moment(reservation.startDate).startOf('day'))
      );
    };
    const isReservationEndingOnDay = (day: moment.Moment) => {
      return this.reservations.some((reservation) =>
        day.isSame(moment(reservation.endDate).startOf('day'))
      );
    };

    for (let i = 0; i < this.maxDaysToReserve; i++) {
      let nextDay = this.date.day.clone().add(i, 'days');

      if (!isReservationStartingFromDay(nextDay)) {
        result.push(nextDay.clone().startOf('day'));
        continue;
      } else if (!isReservationEndingOnDay(nextDay) && i !== 0) {
        result.push(nextDay.clone().startOf('day'));
        break;
      } else if (i !== 0) {
        break;
      }
    }

    if (result.length < this.minDaysToReserve) {
      this.loggerService.warn(
        `No available dates to fulfill the minimum reservation requirement of ${this.minDaysToReserve} days.`
      );
      this.isEnoughDaysSelected = false;
    } else {
      this.isEnoughDaysSelected = true;
    }
    return result;
  };

  async reserve() {
    this.inProgess = true;
    if (this.form.invalid) {
      this.updateErrorMessages(true);
      this.form.markAllAsTouched();
      this.inProgess = false;
      return;
    } else {
      const response = await this.varausService.createReservation({
        startDate: this.date.day.format('YYYY-MM-DD'),
        endDate: moment(this.endDateFormControl.value).format('YYYY-MM-DD'),
        user: {
          email: this.emailFormControl.value || '',
          phoneNumber: this.mobileFormControl.value || '',
          role: 'USER',
        },
        target: this.authService.getTargets()[0],
      });
      this.inProgess = false;
      this.dialogRef.close({
        isSubmit: true,
        response: response,
      });
    }
  };

  updateErrorMessages(updateTermsMessage: boolean = false) {
    this.emailErrorMessage = this.emailFormControl.hasError('required')
      ? 'Sähköposti on pakollinen'
      : this.emailFormControl.hasError('pattern')
      ? 'Sähköposti ei ole validi'
      : '';
    this.mobileErrorMessage = this.mobileFormControl.hasError('required')
      ? 'Puhelinnumero on pakollinen'
      : this.mobileFormControl.hasError('pattern')
      ? 'Puhelinnumero ei ole validi'
      : '';
    this.endDateErrorMessage = this.endDateFormControl.hasError('required')
      ? 'Loppupäivä on pakollinen'
      : '';
    if (updateTermsMessage) {
      this.acceptTermsErrorMessage = this.acceptTermsFormControl.hasError('required')
      ? 'Hyväksy varausehdot'
      : '';
    }
  };

  getReservationsOnDate(date: moment.Moment): ReservationsOnDate {
    const result: ReservationsOnDate = {};
    this.reservations.forEach((reservation) => {
      if (moment(reservation.startDate).isSame(date, 'day')) {
        result.starting = reservation;
      } else if (moment(reservation.endDate).isSame(date, 'day')) {
        result.ending =  reservation;
      } else if (
        moment(reservation.startDate).isBefore(date, 'day') &&
        moment(reservation.endDate).isAfter(date, 'day')
      ) {
        result.during = reservation;
      }
    });
    return result;
  };

  async deleteReservation(id: string) {
    const response = await this.varausService.deleteReservation(id);
    if (response) {
      this.dialogRef.close({
        isSubmit: true,
        response: response,
      });
    }
  };
}
